<template>
	<div
		ref="post_list_item_view"
		class="mt-50 size-px-14 my-shorts-list"
	>
		<div
			class="pa-10-20 justify-space-between"
			@click="onCartels"
		>
			{{ item_cartel.cartl_name }}

			<v-icon>mdi-chevron-down</v-icon>
		</div>
		<div class="box-shadow justify-space-between items-center">
			<div class=" justify-space-between items-center flex-1">
				<div class="pa-10-20">
					<input v-model="item_search.srchtext" :placeholder="$language.shorts.subject_shorts_search"  class="flex-1 search-shorts"/>
				</div>
				<button
					v-if="item_search.srchtext.length > 0"
					@click="item_search.srchtext = ''"
				><img :src="require('@/assets/image/btn_del.svg')" /></button>
			</div>
			<button class="mr-5"><img :src="require('@/assets/image/btn_search.svg')" class="width-80" /></button>
		</div>
		<div
			class="pa-20"
		>
			<div class="my-shorts-list shorts-box1">
				{{ $language.shorts.title_my_shorts }} <!-- 등록 숏플 -->
				<span class="count">{{ item.reg_shrtple_count | makeComma }}개</span>
			</div>
			<div class="mt-20">
				<ul class="flex-row flex-start flex-wrap">
					<li
						v-for="(shorts, c_index) in items"
						:key="'shorts_' + c_index"
						class="mb-10 shorts-list radius-20 position-relative box-shadow"
					>
						<div
							class="radius-20 flex-column justify-center full-height"
							@click="toDetail(shorts)"
						>
							<img :src="shorts.shrtple_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/>
						</div>
						<div
							v-if="shorts.post_state_code == 'CA00900001'"
							class="position-absolute-full flex-column justify-center items-center text-white"
						>
							<div class="position-absolute-full" :class="'shorts-status-' + shorts.post_state_code"></div>
							<div class="position-relative text-center">
								<img :src="require('@/assets/image/icon_clock.svg')" />
								<div class="mt-10">{{ $language.shorts.subject_ing}} <!-- 게시중 --></div>
							</div>
						</div>
						<div
							v-else-if="shorts.post_state_code == 'CA00900004'"
							class="position-absolute-full flex-column justify-center items-center text-red"
							@click="onDelete(shorts)"
						>
							<div class="position-absolute-full" :class="'shorts-status-' + shorts.post_state_code"></div>
							<div class="position-relative text-center">
								<img :src="require('@/assets/image/icon_close_circle_red.svg')" />
								<div class="mt-10">{{ $language.shorts.subject_fail}} <!-- 게시 실패 --></div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getSearch"
		></ScrollPagination>


		<Popup_confirm
			v-if="is_on_delete"

			@click="postDelete"
			@cancel="is_on_delete = false"
		>
			<template
				v-slot:title
			>{{ $language.confirm.delete_bbs.title }}</template>
			<template
				v-slot:main-txt
			>{{  $language.confirm.delete_bbs.main }}</template>
			<template
				v-slot:sub-txt
			>
				{{ $language.confirm.delete_bbs.main }}
			</template>
		</Popup_confirm>

		<PopupHalf
			v-if="is_on_cartels"
			:user="user"
			:title="$language.common.registered_cartel"

			@cancel="is_on_cartels = false"
		>
			<template
				v-slot:half_items
			>
				<li
					v-for="(cartel, c_index) in items_cartel"
					:key="'cartel_' + c_index"
					class="pa-10"
					@click="toList(cartel)"
				>
					{{ cartel.cartl_name }}
				</li>
			</template>
		</PopupHalf>
	</div>
</template>

<script>
	import ScrollPagination from "@/components/ScrollPagination";
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	import PopupHalf from "@/view/Layout/PopupHalf";
	export default{
		name: 'MyShortsList'
		, components: {PopupHalf, Popup_confirm, ScrollPagination}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'MyShortsList'
					, title: this.$language.shorts.title_my_shorts_detail
					, not_footer: true
					, type: 'mypage'
				}
				, item: {}
				, items: []
				, item_search: {
					page_number: 1
					, pagerecnum: 10
					, post_state_code: ''
					, srchtext: ''
				}
				, item_search_cartel: {
					page_number: 1
					, pagerecnum: 10
				}
				, max: false
				, is_add: true
				, items_cartel: []
				, item_cartel: {}
				, max_cartel: false
				, is_add_cartel: true
				, items_status: [
					{code: 'CA00900001', name: '변환중'}
					, {code: 'CA00900002', name: '게시중'}
					, {code: 'CA00900003', name: '삭제'}
					, {code: 'CA00900004', name: '변환실패'}
				]
				, is_on_delete: false
				, item_delete: {
				}
				, is_on_cartels: false
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.is_add = false
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_my_shorts
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, post_state_code: this.item_search.post_state_code
							, srchtext: this.item_search.srchtext
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})
					if(result.success){
						this.item = result.data
						if(result.data.reg_shrtple_list.length > 0){
							if(this.items.length > 0){
								this.items = this.items.concat(result.data.reg_shrtple_list)
							}else{
								this.items = result.data.reg_shrtple_list
							}
							this.max = false
						}else{
							this.max = true
						}

					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_add = true
				}
			}
			, getSearch: function(page){
				this.item_search.page_number = page
				this.getData()
			}
			, toDetail: function(item){
				this.$bus.$emit('to', { name: 'MyShortsDetail', params: {idx: this.$route.params.idx, bbs_id: item.bulletin_number}})
			}
			, toList: function(item){
				this.$bus.$emit('to', { name: 'MyShortsList', type: 'href', params: { idx: item.cartl_number}})
				this.is_on_cartels = false
			}
			, getMyCartel: async function(){
				try{
					this.is_add = false
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_my_shorts_cartel
						, data: {
							member_number: this.user.member_number
							, page_number: this.item_search_cartel.page_number
							, pagerecnum: this.item_search_cartel.pagerecnum
						}
						, type: true
					})
					if(result.success){
						if(result.data.reg_shrtple_cartl_list.length > 0){
							if(this.items_cartel.length > 0){
								this.items_cartel = this.items_cartel.concat(result.data.reg_shrtple_cartl_list)
							}else{
								this.items_cartel = result.data.reg_shrtple_cartl_list
							}
							this.max_cartel = false
						}else{
							this.max_cartel = true
						}

					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_add_cartel = true
				}
			}
			, getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum:1}
							, post_list_page: { page_number: 1, pagerecnum: 1}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onDelete: function(item){
				this.item_delete = item
				this.is_on_delete = true
			}
			, postDelete: async function() {
				try {
					let url = this.$api_url.api_path.post_delete_bbs

					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.item_delete.cartl_number
							, board_number: this.item_delete.board_number
							, bulletin_number: this.item_delete.bulletin_number
						}
						, type: true
					})

					if (result.success) {
						this.items.splice(this.item_delete.index, 1)

					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', {type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
					this.is_on_delete = false
				}
			}
			, getAll: function(){
				this.item_search.post_state_code = ''
				this.item_search.page = 1
				this.items = []
				this.getData()
			}
			, getIng: function(){
				this.item_search.post_state_code = 'CA00900002'
				this.item_search.page = 1
				this.items = []
				this.getData()
			}
			, onCartels: function(){
				this.is_on_cartels = true
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getCartel()
			this.getMyCartel()
		}
	}
</script>

<style>
	.my-shorts-list .short-search {
		color: var(--gray01, #BBB);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
	}

	.my-shorts-list .shorts-box1 {
		color: var(--dark-Gray02, #333);
		font-size: 14px;
		font-style: normal;
		font-weight: 500 !important;
		line-height: 100%; /* 14px */
	}

	.my-shorts-list .shorts-box1 .count {

		color: var(--dark-Gray02, #333);
		font-size: 1.4rem;
		font-style: normal;
		font-weight: 500;
		line-height: 100%;
	}

	.my-shorts-list .shorts-box2 {
		display: flex;
		width: 162.5px;
		padding: 10px 14px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 6px;
		border-radius: 12px;
		background: var(--light-Gray01, #E9E9E9);
		color: var(--gray02)
	}

	.my-shorts-list .shorts-box2 .count {

		color: var(--gray02, #888);
		text-align: center;
		font-family: Open Sans;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 16px */
	}

	.my-shorts-list .shorts-list {
		flex-basis: calc(50% - 5px);
	}

	.my-shorts-list .shorts-list:not(:nth-child(2n)){
		margin-right: 10px;
	}

	.my-shorts-list .shorts-status-CA00900001 {
		border-radius: 20px;
		opacity: 0.5;
		background: var(--black, #000);
	}
	.my-shorts-list .shorts-status-CA00900002 {

	}
	.my-shorts-list .shorts-status-CA00900004 {

		border-radius: 20px;
		opacity: 0.5;
		background: var(--black, #000);
	}
	.my-shorts-list .shorts-status-CA00900003 {
		visibility: none
	}

	.my-shorts-list .text-white {
		color: var(--white, #FFF);
		font-family: Noto Sans CJK KR;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 12px */
	}


	.my-shorts-list .text-red {
		color: var(--red, #DC505C);
		font-family: Noto Sans CJK KR;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 12px */
	}
</style>